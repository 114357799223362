<template>
  <div class="base-panel-filter">
    <slot name="panelFilterPrepend"></slot>
    <panel-filter-items
      v-for="(item, i) in items"
      :key="i"
      :item="item"
      :index="i"
      v-bind="$attrs"
      v-on="$listeners"
    ></panel-filter-items>
  </div>
</template>
<script>
export default {
  name: "view-panel-filter",
  components: {
    panelFilterItems: () => import("./panelFilterItems.vue"),
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.base-panel-filter {
  background-color: var(--v-white-base);
  padding: 20px;
  width: 268px;
  border-radius: 10px;
  height: 100%;
}
</style>
